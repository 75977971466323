// 遗产总图
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'BHGLJFLX_BM',
                label: '保护管理经费类型',
                minWidth: 105,
            }, {
                prop: 'BHGLJF',
                label: '保护管理经费(元)',
                minWidth: 105,
                splitByComma: true
            }, {
                prop: 'JFHQSJ',
                label: '经费获取时间',
                substr: true
            },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        selectData: [
            // {
            //     "label": "保护管理经费类型",
            //     "clearable": true,
            //     "placeholder": "请选择保护管理经费类型",
            //     "list": [
            //         { "ID": "中央财政", "NAME": "中央财政" },
            //         { "ID": "省财政", "NAME": "省财政" },
            //         { "ID": "市财政", "NAME": "市财政" },
            //         { "ID": "其他", "NAME": "其他" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "BHGLJFLX",
            //     "operateType": "search",
            //     "isShow": true,
            //     "width": "180px"
            // },
            // {
            //     "label": "经费的项目类型",
            //     "clearable": true,
            //     "placeholder": "请选择经费的项目类型",
            //     "list": [
            //         { "ID": "人员公用", "NAME": "人员公用" },
            //         { "ID": "保护工程", "NAME": "保护工程" },
            //         { "ID": "环境与整治工程", "NAME": "环境与整治工程" },
            //         { "ID": "展示工程", "NAME": "展示工程" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "JFXMLX",
            //     "operateType": "search",
            //     "isShow": true,
            //     "width": "168px"
            // }
        ],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
};
export default selfData;